import React from "react";
import PropTypes from "prop-types";
import ProductCurvyLines from "../Assets/images/productCurvyLines.png";
import Calendar from "../Assets/images/calendar.svg";
import Childrens from "../Assets/images/children.svg";
import LocationPin from "../Assets/images/locationpin.svg";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		backgroundColor: theme.palette.secondary.light,
		overflow: "hidden",
	},
	container: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(15),
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	item: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(0, 5),
	},
	title: {
		marginBottom: theme.spacing(14),
	},
	number: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
	image: {
		height: 55,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	curvyLines: {
		pointerEvents: "none",
		position: "absolute",
		top: -180,
		opacity: 0.7,
	},
	button: {
		marginTop: theme.spacing(8),
	},
}));

function ProductHowItWorks(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<img
					src={ProductCurvyLines}
					className={classes.curvyLines}
					alt="curvy lines"
				/>
				<Typography
					variant="h4"
					marked="center"
					className={classes.title}
					component="h2"
				></Typography>
				<div>
					<Grid container spacing={5}>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<div className={classes.number}>1.</div>
								<img src={Calendar} alt={""} className={classes.image} />
								<Typography variant="h5" align="center">
									{t("makeAppointmentDescription")}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<div className={classes.number}>2.</div>
								<img src={LocationPin} alt="graph" className={classes.image} />
								<Typography variant="h5" align="center">
									{t("arriveToDestination")}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div className={classes.item}>
								<div className={classes.number}>3.</div>
								<img src={Childrens} alt="clock" className={classes.image} />
								<Typography variant="h5" align="center">
									{t("programDescription")}
								</Typography>
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</section>
	);
}

ProductHowItWorks.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default ProductHowItWorks;
