import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import {
	ButtonBase,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import Button from "../UI/Button";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(4),
	},
	images: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexWrap: "wrap",
	},
	imageWrapper: {
		position: "relative",
		display: "block",
		padding: 0,
		borderRadius: 0,
		height: "40vh",
		[theme.breakpoints.down("sm")]: {
			width: "100% !important",
			height: 100,
		},
		"&:hover": {
			zIndex: 1,
		},
		"&:hover $imageBackdrop": {
			opacity: 0.15,
		},
		"&:hover $imageMarked": {
			opacity: 0,
		},
	},
	imageButton: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: theme.palette.common.white,
	},
	imageSrc: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: "cover",
		backgroundPosition: "center 40%",
	},
	imageBackdrop: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		background: theme.palette.common.black,
		opacity: 0.5,
		transition: theme.transitions.create("opacity"),
	},
	imageTitle: {
		position: "relative",
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		background: theme.palette.common.white,
		position: "absolute",
		bottom: -2,
		left: "calc(50% - 9px)",
		transition: theme.transitions.create("opacity"),
	},
}));

function ProductCategories(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const images = [
		{
			url: "https://images.unsplash.com/photo-1608790672275-309c02d888ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
			title: t("birthdayCardTitle"),
			width: "50%",
			popUpTitle: t("birthdayPopUpTitle"),
			popUpText: t("birthdayPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1625388965538-835c47d1d1e4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
			title: t("gamesCardTitle"),
			width: "30%",
			popUpTitle: t("gamesCardPopUpTitle"),
			popUpText: t("gamesCardPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1517164850305-99a3e65bb47e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
			title: t("outsideProgamsTitle"),
			width: "20%",
			popUpTitle: t("outsideProgramsPopUpTitle"),
			popUpText: t("outsideProgramsPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1577896852618-01da1dd79f7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
			title: t("insideProgramsTitle"),
			width: "20%",
			popUpTitle: t("insideProgramsPopUpTitle"),
			popUpText: t("insideProgramsPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1605627079912-97c3810a11a4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1407&q=80",
			title: t("handmadeProgramsTitle"),
			width: "34%",
			popUpTitle: t("handmadeProgramsPopUpTitle"),
			popUpText: t("handmadeProgramsPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1596066190600-3af9aadaaea1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
			title: t("developerProgramsTitle"),
			width: "46%",
			popUpTitle: t("developerProgramsPopUpTitle"),
			popUpText: t("developerProgramsPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1629760946220-5693ee4c46ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
			title: t("boardGamesTitle"),
			width: "40%",
			popUpTitle: t("boardGamesPopUpTitle"),
			popUpText: t("boardGamesPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1511448962213-2f9bc14ed197?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
			title: t("campsTitle"),
			width: "20%",
			popUpTitle: t("campsPopUpTitle"),
			popUpText: t("campsPopUpText"),
		},
		{
			url: "https://images.unsplash.com/photo-1612297561428-6ca218aec064?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80",
			title: t("familyProgramsTitle"),
			width: "40%",
			popUpTitle: t("familyProgramsPopUpTitle"),
			popUpText: t("familyProgramsPopUpText"),
		},
	];
	const [open, setOpen] = useState(false);
	const [popUpTitle, setPopUpTitle] = useState(null);
	const [popUpText, setPopUpText] = useState(null);

	const handleClickOpen = (image) => {
		setPopUpTitle(image.popUpTitle);
		setPopUpText(image.popUpText);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Container className={classes.root} component="section">
			<Typography variant="h4" marked="center" align="center" component="h2">
				{t("productsTitle")}
			</Typography>
			<div className={classes.images}>
				{images.map((image) => (
					<ButtonBase
						onClick={() => handleClickOpen(image)}
						key={image.title}
						className={classes.imageWrapper}
						style={{
							width: image.width,
						}}
					>
						<div
							className={classes.imageSrc}
							style={{
								backgroundImage: `url(${image.url})`,
							}}
						/>
						<div className={classes.imageBackdrop} />
						<div className={classes.imageButton}>
							<Typography
								component="h3"
								variant="h6"
								color="inherit"
								className={classes.imageTitle}
							>
								{image.title}
								<div className={classes.imageMarked} />
							</Typography>
						</div>
					</ButtonBase>
				))}
			</div>
			<Dialog
				theme={"dark"}
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{popUpTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{popUpText}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("closePopUpButton")}</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

ProductCategories.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default ProductCategories;
