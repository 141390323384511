import {
	Alert,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { signInAnonymously } from "firebase/auth";
import { getDatabase, push, ref } from "firebase/database";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { auth } from "../FirebaseConfig";
import { useLocation } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import ReCAPTCHA from "react-google-recaptcha";
import { AppBar, Box, Toolbar } from "@mui/material";
import one from "../Assets/images/1.png";
import two from "../Assets/images/2.png";
import three from "../Assets/images/3.png";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(8),
	},

	appointmentEmailInput: {
		width: 100,
	},
	test: {
		backgroundColor: "red",
		width: "100%",
	},
}));

export default function Apointment() {
	const classes = useStyles();
	const [date, setDate] = useState(null);
	const [startHour, setStartHour] = useState();
	const [stayHour, setStayHour] = useState();
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [childrenName, setChildrenName] = useState("");
	const [parentName, setParentName] = useState("");
	const [userId, setUserId] = useState("");
	const [description, setDescription] = useState("");
	const [childrenAge, setChildrenAge] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
	const [parentsNumber, setParentsNumber] = useState("");
	const [childrenNumber, setChildrenNumber] = useState("");
	const [childrenBirthDate, setChildrenBirthDate] = useState(null);
	const { state } = useLocation();
	const { type } = state ? state : "camp";
	const [openSuccess, setOpenSuccess] = useState(false);
	const reCaptchaRef = React.createRef();
	const [alert, setAlert] = useState(false);
	const [techAlert, setTechAlert] = useState(false);

	const [turn, setTurn] = useState("");

	useEffect(() => {
		if (!userId) {
			login(auth);
		}
	}, [userId]);

	const login = (auth) => {
		signInAnonymously(auth)
			.then((response) => {
				setUserId(response.user.uid);
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
			});
	};

	const resetForm = () => {
		setDate(null);
		setStartHour(null);
		setStayHour(null);
		setEmail("");
		setPhone("");
		setChildrenName("");
		setParentName("");
		setDescription("");
		setChildrenAge("");
		setPaymentMethod("");
		setPaymentMethod("");
		setParentsNumber("");
		setChildrenNumber("");
		setChildrenBirthDate("");
		setTurn("");
	};

	const writeUserData = (type) => {
		if (reCaptchaRef.current.getValue() !== "") {
			login(auth);
			if (userId) {
				const db = getDatabase();
				if (type === "camp") {
					if (
						childrenName &&
						parentName &&
						childrenAge &&
						paymentMethod &&
						turn &&
						email &&
						phone
					) {
						push(ref(db, "appointments/"), {
							userId,
							email,
							type,
							phone,
							childrenName,
							parentName,
							description,
							paymentMethod,
							childrenAge,
							turn: turn,
						});
					} else {
						setAlert(true);
					}
				} else {
					push(ref(db, "appointments/"), {
						userId,
						startDate: moment(date).add(startHour, "h").toString(),
						endDate: moment(date).add(stayHour, "h").toString(),
						email,
						type,
						phone,
						childrenName,
						parentName,
						description,
						paymentMethod,
						childrenAge,
						childrenBirthDate: childrenBirthDate
							? moment(childrenBirthDate).toString()
							: "",
						parentsNumber,
						childrenNumber,
						status: "NEW",
						turn: turn,
					});
				}
				setOpenSuccess(true);
				resetForm();
			} else {
				setTechAlert(true);
			}
		} else {
			setTechAlert(true);
		}
	};

	const handleClose = () => {
		setOpenSuccess(false);
	};

	const handleAlertClose = () => {
		setAlert(false);
	};

	const handleTechAlertClose = () => {
		setTechAlert(false);
	};

	const handleChange = (value) => {
		this.setState({ value });
		// if value is null recaptcha expired
		if (value === null) this.setState({ expired: "true" });
	};

	return (
		<div className={classes.root}>
			<Box>
				<Toolbar>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item textAlign={"center"} xs={4}>
							<img border="0" src={one} alt="wonder" width="20%" />
						</Grid>
						<Grid item textAlign={"center"} xs={4}>
							<img src={two} alt="wonder" width="20%" />
						</Grid>
						<Grid item textAlign={"center"} xs={4}>
							<img src={three} alt="wonder" width="20%" />
						</Grid>
						<Grid item textAlign={"center"} xs={12}>
							<Typography sx={{ fontStyle: "italic" }}>
								Conținutul acestui material nu reprezintă în mod obligatoriu
								poziția oficială a Uniunii Europene sau a Guvernului României
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>

				<Toolbar />
			</Box>
			{type === "oneTimeTicket" || type === "pass" || type === "camp" ? (
				<>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={4}
					>
						<Grid item md={12} xs={12}>
							{type === "oneTimeTicket" && (
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={4}
								>
									<Grid item md={12} xs={12}>
										<Typography variant="h3">Egy alkalmas jegy</Typography>
									</Grid>
									<Grid item md={12} xs={12}>
										<Typography variant="p0">25 RON / Óra</Typography>
									</Grid>
								</Grid>
							)}
							{type === "pass" && <Typography variant="h3">Bérlet</Typography>}
							{type === "camp" && <Typography variant="h3">Tábor</Typography>}
						</Grid>
						{type === "camp" ? (
							<Grid item xs={12}>
								<Typography variant="body2">
									A Csalogány őszi vakációs táborában mindent megteszünk azért,
									hogy a gyerekek jól érezzék magukat úgy, hogy közben
									tanuljanak is.
								</Typography>
								<Typography variant="body2">
									🗓 Dátum: Október 30. - November 3.
								</Typography>
								<Typography variant="body2">
									🕘 Időpont:Minden nap 9:00 - 15:00 óra között
								</Typography>
								<Typography variant="body2">
									🏡 Helyszín: Csalogány Gyerekfoglalkoztató, Tudor Vladimirescu
									utca 14.
								</Typography>
								<Typography variant="body2">
									A tábor részvételi díja 450 lej, ami tartalmazza:
								</Typography>
								<Typography variant="body2">👉 az uzsonnát és</Typography>
								<Typography variant="body2">
									👉 a napi egyszeri étkezést (leves és főétel),
								</Typography>
								<Typography variant="body2">
									👉 valamint minden olyan alapanyagot, amire a programokhoz
									szükség van.
								</Typography>
								<Typography variant="body2">
									✂ Külön napokra is lehet jelentkezni 110 lejes napi díjjal (ez
									esetben kérlek a megjegyzésben ezt feltüntetni), így ha csak
									egy-két napot szeretnél csatlakozni, akkor is szívesen várunk.
								</Typography>
								<Typography variant="body2">
									👦👧 Korosztály: 4-10 éves gyerekek (vegyes korosztály)
								</Typography>
								<Typography variant="body2">
									Az őszi program témája a termés betakarítása körül forog,
									amelynek részeként izgalmas mesékkel és kreatív kézműves
									tevékenységekkel foglalkozunk.
								</Typography>
							</Grid>
						) : null}
						<Grid item md={6} xs={12}>
							<TextField
								id="outlined-basic"
								label="Gyerek teljes neve"
								variant="outlined"
								fullWidth
								value={childrenName}
								onChange={(e) => {
									setChildrenName(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								id="outlined-basic"
								label="Szülő teljes neve"
								variant="outlined"
								fullWidth
								value={parentName}
								onChange={(e) => {
									setParentName(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Gyerek életkora
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={childrenAge}
									label="Gyerek életkora"
									onChange={(e) => {
										setChildrenAge(e.target.value);
									}}
								>
									<MenuItem value={3}>3 év</MenuItem>
									<MenuItem value={4}>4 év</MenuItem>
									<MenuItem value={5}>5 év</MenuItem>
									<MenuItem value={6}>6 év</MenuItem>
									<MenuItem value={7}>7 év</MenuItem>
									<MenuItem value={8}>8 év</MenuItem>
									<MenuItem value={9}>9 év</MenuItem>
									<MenuItem value={10}>10 év</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={4} xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Fizetési mód
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={paymentMethod}
									label="Fizetési mód"
									onChange={(e) => {
										setPaymentMethod(e.target.value);
									}}
								>
									<MenuItem value={"card"}>Kártya</MenuItem>
									<MenuItem value={"cash"}>Készpénz</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						{type !== "camp" ? (
							<Grid item md={4} xs={12}>
								<DesktopDatePicker
									label="Érkezés dátuma"
									disableFuture={false}
									inputFormat="MM/DD/YYYY"
									value={date}
									minDate={moment().add(1, "days")}
									onChange={(value) => {
										setDate(moment(value, "DD/MM/YYYY"));
									}}
									renderInput={(params) => <TextField fullWidth {...params} />}
								/>
							</Grid>
						) : (
							""
						)}
						{type !== "camp" ? (
							<Grid item md={2} xs={12}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">
										Érkezés ideje
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={startHour}
										label="Időtartam"
										onChange={(e) => {
											setStartHour(e.target.value);
										}}
									>
										<MenuItem value={12}>12 óra</MenuItem>
										<MenuItem value={13}>13 óra</MenuItem>
										<MenuItem value={14}>14 óra</MenuItem>
										<MenuItem value={15}>15 óra</MenuItem>
										<MenuItem value={16}>16 óra</MenuItem>
										<MenuItem value={17}>17 óra</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						) : (
							<Grid item md={4} xs={12}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">Időszak</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={turn}
										label="Időtartam"
										onChange={(e) => {
											setTurn(e.target.value);
										}}
									>
										<MenuItem disabled value={"agust_14_18"}>
											Agusztus 14. - Agusztus 18.
										</MenuItem>
										<MenuItem disabled value={"agust_21_25"}>
											Agusztus 21. - Agusztus 25.
										</MenuItem>
										<MenuItem disabled value={"agust_28_sept_1"}>
											Agusztus 28. - Szeptember 1.
										</MenuItem>
										<MenuItem disabled value={"sept_4_sept_8"}>
											Szeptember 4. - Szeptember 8.
										</MenuItem>
										<MenuItem value={"oct_30_nov_3"}>
											Október 30. - November 3.
										</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						)}
						{type !== "camp" ? (
							<Grid item md={2} xs={12}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">
										Tavozás ideje
									</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={stayHour}
										label="Időtartam"
										onChange={(e) => {
											setStayHour(e.target.value);
										}}
									>
										<MenuItem value={13}>13 óra</MenuItem>
										<MenuItem value={14}>14 óra</MenuItem>
										<MenuItem value={15}>15 óra</MenuItem>
										<MenuItem value={16}>16 óra</MenuItem>
										<MenuItem value={17}>17 óra</MenuItem>
										<MenuItem value={18}>18 óra</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						) : (
							""
						)}
						<Grid item xs={12} md={7}>
							<TextField
								id="outlined-basic"
								label="Email"
								variant="outlined"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<MuiTelInput
								style={{ width: "100%" }}
								value={phone}
								label="Telefonszám"
								onChange={(e) => setPhone(e)}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								id="outlined-basic"
								label="Megjegyzés"
								variant="outlined"
								fullWidth
								placeholder="Bármi fajta speciális igény a gyerekel kapcsolatban"
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								multiline
								rows={4}
							/>
						</Grid>
						<Grid item md={5} xs={12}>
							<ReCAPTCHA
								style={{ display: "inline-block" }}
								ref={reCaptchaRef}
								sitekey="6LeyV4UnAAAAAIfxln7LSdtG-vxJgH_GWsFn1QJb"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={10} xs={12}>
							<Button variant="contained" onClick={() => writeUserData(type)}>
								Időpont foglalás
							</Button>
						</Grid>
					</Grid>
				</>
			) : (
				<>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={4}
					>
						<Grid item md={12} xs={12}>
							<Typography variant="h3">Szülinap</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography variant="body2">
								Alapcsomag (10 gyerek esetén): 400 Ron Hétvégén/ünnepnapokon
								plusz 50 Ron (450 Ron 3 óra)
							</Typography>
							<Typography variant="body2">Az ár tartalmazza:</Typography>
							<Typography variant="body2">
								• Terem használati díj 3 órára
							</Typography>
							<Typography variant="body2">• Játékok használata</Typography>

							<Typography variant="body2">
								• Terem díszitése és ünnepi teríték előkészítése
							</Typography>
							<Typography variant="body2">
								10 gyerek fölött minden gyerek után még 35 Ron -t kell még
								füzetni.
							</Typography>
							<Typography variant="body2">
								A 3 óra terem használat után minden megkezdett félóra 50 Ron.
							</Typography>
							<Typography variant="body2">
								Foglalkoztatás-gyerekprogram:
							</Typography>
							<Typography variant="body2">
								• 50 Ron/óra/programvezető (7 gyerek alatt egy programvezető,
								7-14 között 2, 14-gyerek fölött 3 programvezető) Választható
								extrák:
							</Typography>
							<Typography variant="body2">
								• Arc festés: 10 Ron/gyermek
							</Typography>
							<Typography variant="body2">
								• Tortára szám gyertya: 10 Ron
							</Typography>
							<Typography variant="body2">
								• Számos lufi (ezüst/arany): 20 Ron
							</Typography>
							<Typography variant="body2">• Színes szalvéta: 8 Ron</Typography>
							<Typography variant="body2">• Színes szalvéta: 8 Ron</Typography>
							<Typography variant="body2">• Gyerekpezsgő: 20 Ron</Typography>
							<Typography variant="body2">
								• Találkozás egy Disney mesefigurával (Miki Mouse, Mini): 300
								Ron/óra Az extrák csak az esetben rendelhetőek meg, ha előre le
								vannak beszélve! Másképp nem lesz rá lehetőségünk időben
								beszerezni ezeket!
							</Typography>
							<Typography variant="body2">Fontos információ:</Typography>
							<Typography variant="body2">
								Az születésnapra szükséges torta, étel és ital a megrendelőt
								illeti.
							</Typography>
							<Typography variant="body2">
								A tortát csak az esetben lehet behozni, ha az ehhez szükséges
								kisérő dokumentum is megvan.
							</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								id="outlined-basic"
								label="Gyerek teljes neve"
								variant="outlined"
								fullWidth
								value={childrenName}
								onChange={(e) => {
									setChildrenName(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								id="outlined-basic"
								label="Szülő teljes neve"
								variant="outlined"
								fullWidth
								value={parentName}
								onChange={(e) => {
									setParentName(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={4} xs={12}>
							<DesktopDatePicker
								label="Gyerek születési dátuma"
								inputFormat="MM/DD/YYYY"
								value={childrenBirthDate}
								onChange={setChildrenBirthDate}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={1} xs={12}>
							<TextField
								id="outlined-basic"
								label="Felnöttek száma"
								variant="outlined"
								type="number"
								fullWidth
								value={parentsNumber}
								onChange={(e) => {
									setParentsNumber(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={1} xs={12}>
							<TextField
								id="outlined-basic"
								label="Gyerekek száma"
								variant="outlined"
								type="number"
								fullWidth
								value={childrenNumber}
								onChange={(e) => {
									setChildrenNumber(e.target.value);
								}}
							/>
						</Grid>
						<Grid item md={2} xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Fizetési mód
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={paymentMethod}
									label="Fizetési mód"
									onChange={(e) => {
										setPaymentMethod(e.target.value);
									}}
								>
									<MenuItem value={1}>Kártya</MenuItem>
									<MenuItem value={2}>Készpénz</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={4} xs={12}>
							<DesktopDatePicker
								label="Érkezés dátuma"
								disableFuture={false}
								inputFormat="MM/DD/YYYY"
								value={date}
								minDate={moment().add(1, "days")}
								onChange={(value) => {
									setDate(moment(value, "DD/MM/YYYY"));
								}}
								renderInput={(params) => <TextField fullWidth {...params} />}
							/>
						</Grid>
						<Grid item md={2} xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Érkezés ideje
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={startHour}
									label="Időtartam"
									onChange={(e) => {
										setStartHour(e.target.value);
									}}
								>
									<MenuItem value={12}>12 óra</MenuItem>
									<MenuItem value={13}>13 óra</MenuItem>
									<MenuItem value={14}>14 óra</MenuItem>
									<MenuItem value={15}>15 óra</MenuItem>
									<MenuItem value={16}>16 óra</MenuItem>
									<MenuItem value={17}>17 óra</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item md={2} xs={12}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">
									Tavozás ideje
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={stayHour}
									label="Időtartam"
									onChange={(e) => {
										setStayHour(e.target.value);
									}}
								>
									<MenuItem value={13}>13 óra</MenuItem>
									<MenuItem value={14}>14 óra</MenuItem>
									<MenuItem value={15}>15 óra</MenuItem>
									<MenuItem value={16}>16 óra</MenuItem>
									<MenuItem value={17}>17 óra</MenuItem>
									<MenuItem value={18}>18 óra</MenuItem>
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={3}>
							<TextField
								id="outlined-basic"
								label="Email"
								variant="outlined"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={5}>
							<MuiTelInput
								style={{ width: "100%" }}
								value={phone}
								label="Telefonszám"
								onChange={(e) => setPhone(e)}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								id="outlined-basic"
								label="Megjegyzés"
								variant="outlined"
								fullWidth
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								multiline
								rows={4}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<ReCAPTCHA
								style={{ display: "inline-block" }}
								ref={reCaptchaRef}
								sitekey="6LeyV4UnAAAAAIfxln7LSdtG-vxJgH_GWsFn1QJb"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Button variant="contained" onClick={() => writeUserData(type)}>
								Időpont foglalás
							</Button>
						</Grid>
					</Grid>
				</>
			)}
			<Snackbar
				open={openSuccess}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert severity="success">Időpont foglalását továbítottuk</Alert>
			</Snackbar>
			<Snackbar open={alert} autoHideDuration={6000} onClose={handleAlertClose}>
				<Alert severity="error">Kérem adjon meg minden információt</Alert>
			</Snackbar>
			<Snackbar
				open={techAlert}
				autoHideDuration={6000}
				onClose={handleTechAlertClose}
			>
				<Alert severity="error">
					Technikai hiba történt kérem probálkozzon újra később.
				</Alert>
			</Snackbar>
		</div>
	);
}
