import {
	Box,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { child, get, getDatabase, ref } from "firebase/database";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TicketAppointments from "./TicketAppointments";
import PassAppointment from "./PassAppointment";
import BirthdayAppointments from "./BirthdayAppointments";
import TicketAppointmentCalendar from "../components/TicketAppointmentCalendar";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function Admin() {
	const classes = useStyles();
	const navigate = useNavigate();
	const [appointmentList, setAppointmentList] = useState([]);

	useEffect(() => {
		checkIfLoggedIn();
		getAppointments();
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			checkIfLoggedIn();
			getAppointments();
		}, 300000);

		return () => clearInterval(interval);
	}, []);

	const checkIfLoggedIn = () => {
		let accessToken = localStorage.getItem("accessToken");
		if (!accessToken) {
			navigate("/adminlogin");
		}
	};

	const getAppointments = () => {
		const auth = getAuth();
		onAuthStateChanged(auth, (user) => {
			if (user) {
				const dbRef = ref(getDatabase());
				get(child(dbRef, `appointments/`))
					.then((snapshot) => {
						if (snapshot.exists()) {
							let tmpTicketValues = [];
							snapshot.forEach((child) => {
								let tmpConverter = {
									id: child.key,
									...child.val(),
								};
								tmpTicketValues.push(tmpConverter);
							});

							let tmpBirthday = [];
							let tmpPass = [];
							let tmpTicket = [];
							tmpTicketValues.forEach((element) => {
								if (element.type === "oneTimeTicket") {
									tmpTicket.push(element);
								}
								if (element.type === "pass") {
									tmpPass.push(element);
								}
								if (element.type === "birthday") {
									tmpBirthday.push(element);
								}
							});

							setAppointmentList(tmpTicketValues);
						} else {
							setAppointmentList([]);
						}
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				navigate("/adminlogin");
			}
		});
	};

	return (
		<div className={classes.root}>
			<TicketAppointmentCalendar appointments={appointmentList} />
		</div>
	);
}

export default Admin;
