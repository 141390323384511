import React from "react";
import PropTypes from "prop-types";
import ProductCurvyLines from "../Assets/images/productCurvyLines.png";
import Place from "../Assets/images/place.svg";
import Goal from "../Assets/images/goal.svg";
import Time from "../Assets/images/time.svg";
import Tree from "../Assets/images/tree.svg";
import Bird from "../Assets/images/bird.svg";
import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		overflow: "hidden",
		backgroundColor: theme.palette.secondary.light,
	},
	container: {
		marginTop: theme.spacing(15),
		marginBottom: theme.spacing(30),
		display: "flex",
		position: "relative",
	},
	image: {
		height: 55,
	},
	title: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
	},
	curvyLines: {
		pointerEvents: "none",
		position: "absolute",
		top: -180,
	},
}));

function ProductValues(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				<img
					src={ProductCurvyLines}
					className={classes.curvyLines}
					alt="curvy lines"
				/>
				<Grid container spacing={9}>
					<Grid item xs={12} md={4}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Grid item className={classes.item}>
								<img className={classes.image} src={Place} alt="" />
							</Grid>
							<Grid item textAlign={"center"} className={classes.item}>
								<Typography
									textAlign={"center"}
									variant="h4"
									className={classes.title}
								>
									{t("where")}
								</Typography>
							</Grid>
							<Grid item textAlign={"center"} className={classes.item}>
								<Typography textAlign={"center"} variant="h6" align="center">
									{t("whereDescription")}
									<a href="https://goo.gl/maps/bAdWArejv5riBZV2A">
										{t("address")}
									</a>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Grid item className={classes.item}>
								<img className={classes.image} src={Goal} alt="" />
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h4" className={classes.title}>
									{t("why")}
								</Typography>
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h6" align="center">
									{t("whyDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Grid item className={classes.item}>
								<img className={classes.image} src={Time} alt="" />
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h4" className={classes.title}>
									{t("when")}
								</Typography>
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h6" align="center">
									{t("whenDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Grid item className={classes.item}>
								<img className={classes.image} src={Tree} alt="" />
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h4" className={classes.title}>
									{t("what")}
								</Typography>
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h6" align="center">
									{t("whatDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							<Grid item className={classes.item}>
								<img className={classes.image} src={Bird} alt="" />
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h4" className={classes.title}>
									{t("how")}
								</Typography>
							</Grid>
							<Grid item className={classes.item}>
								<Typography variant="h6" align="center">
									{t("howDescription")}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
}

ProductValues.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default ProductValues;
