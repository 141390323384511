import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import {
	Button,
	Container,
	Grid,
	Hidden,
	Snackbar,
	Typography,
} from "@mui/material";
import TextInput from "../components/Utils/TextInput";
import { useState } from "react";
import jsonp from "jsonp";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(10),
		marginBottom: 35,
		display: "flex",
	},
	cardWrapper: {
		zIndex: 1,
	},
	card: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: theme.palette.warning.main,
		padding: theme.spacing(8, 3),
	},
	cardContent: {
		maxWidth: 400,
	},
	textField: {
		width: "100%",
		backgroundColor: "white",
		marginBottom: 100,
	},
	button: {
		width: "100%",
		marginTop: theme.spacing(8),
		backgroundColor: "#000000  !important",
	},
	imagesWrapper: {
		position: "relative",
	},
	image: {
		position: "absolute",
		top: -28,
		left: -28,
		right: 0,
		bottom: 0,
		width: "100%",
		maxWidth: 600,
	},
}));

function Newsletter(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState();
	const { t } = useTranslation();
	const subscribeToNewsLetter = () => {
		setOpen(true);
		const url =
			"https://gmail.us13.list-manage.com/subscribe/post?u=2e178ec7827044a7d2fa31be1&amp;id=536db6ac19&amp;f_id=002697e2f0";
		jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (_, data) => {});
		setEmail();
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Container className={classes.root} component="section">
			<Grid container>
				<Grid item xs={12} md={6} className={classes.cardWrapper}>
					<div className={classes.card}>
						<div className={classes.cardContent}>
							<Typography variant="h2" component="h2" gutterBottom>
								{t("subscribeToNewsLetter")}
							</Typography>

							<Grid
								container
								direction="column"
								justifyContent="space-around"
								alignItems="stretch"
								spacing={2}
							>
								<Grid item xs={12}>
									<Typography variant="h5">
										{t("newsletterSubscriptionDescriptoption")}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										noBorder
										className={classes.textField}
										placeholder="Email"
										value={email}
										onChange={(e) => {
											setEmail(e.target.value);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										onClick={(e) => subscribeToNewsLetter()}
										className={classes.button}
										size="large"
										variant="contained"
									>
										{t("subscribe")}
									</Button>
								</Grid>
							</Grid>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} className={classes.imagesWrapper}>
					<Hidden smDown>
						{/* @ TODO - REPLACE IMAGE DOTS */}
						{/* <div className={classes.imageDots} />  */}
						<img
							src="https://images.unsplash.com/reserve/uZYSV4nuQeyq64azfVIn_15130980706_64134efc6e_o.jpg?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1467&q=80"
							alt="call to action"
							className={classes.image}
						/>
					</Hidden>
				</Grid>
			</Grid>
			<Snackbar
				open={open}
				onClose={handleClose}
				message="Sikeresen felíratkoztál"
			/>
		</Container>
	);
}

Newsletter.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default Newsletter;
