import * as React from "react";
import children from "../Assets/videos/children.mp4";
import { makeStyles } from "@mui/styles";
import Header from "../components/Header";
import { Grid } from "@mui/material";
import csaloganyLogo from "../Assets/images/csaloganyLogo.png";
import headerPicture from "../Assets/images/headerPicture.jpg";

const useStyles = makeStyles((theme) => ({
	background: {
		backgroundColor: "#7fc7d9",
		backgroundPosition: "center",
		backgroundImage: headerPicture,
	},
	h5: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		[theme.breakpoints.up("sm")]: {
			marginTop: theme.spacing(10),
		},
	},
	more: {
		marginTop: theme.spacing(2),
	},
	dynamicBackground: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		background: "#03a9f4",
		mixBlendMode: "overlay",
	},
	dynamicVideo: {
		zIndex: -1,
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover",
		opacity: 1,
	},
	projectImages: {
		position: "fixed",
		left: 0,
		top: -50,
		// backgroundColor: "white",
		width: 450,
		marginTop: 0,
		height: 200,
		display: "flex",
		alignItems: "center",
	},
}));

export default function HomePage() {
	const classes = useStyles();

	return (
		<Header backgroundClassName={classes.dynamicBackground}>
			<>
				<video
					src={children}
					muted
					loop
					autoPlay
					poster={headerPicture}
					className={classes.dynamicVideo}
				/>
			</>
			<Grid
				container
				direction="column"
				justifyContent="flex-end"
				alignItems="center"
				spacing={12}
			>
				<Grid item textAlign={"center"}>
					<img src={csaloganyLogo} alt="wonder" width="50%" height="60%" />
				</Grid>
			</Grid>
		</Header>
	);
}
