import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../FirebaseConfig";
import { Alert, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
	},
}));

function AdminLogin() {
	const classes = useStyles();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loginErrorMessage, setLoginErrorMessage] = useState("");
	const [openError, setOpenError] = useState(false);
	const navigate = useNavigate();
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		checkIfLoggedIn();
	}, [isLoggedIn]);

	const handleClose = () => {
		setOpenError(false);
	};

	const checkIfLoggedIn = () => {
		let accessToken = localStorage.getItem("accessToken");
		if (accessToken) {
			navigate("/admin");
		}
	};

	const login = (auth) => {
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				localStorage.setItem("accessToken", user.accessToken);
				setIsLoggedIn(true);
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;

				setLoginErrorMessage(errorMessage);
				setOpenError(true);
			});
	};

	return (
		<div className={classes.root}>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={4}
			>
				<Grid item xs={12}>
					<Typography variant="h5">Csaloagány adminisztrátor oldal</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="outlined-basic"
						label="Email"
						variant="outlined"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="outlined-basic"
						label="Password"
						variant="outlined"
						type="password"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						fullWidth
					/>
				</Grid>

				<Grid item xs={6}>
					<Button onClick={() => login(auth)}>Bejelentkezés</Button>
				</Grid>
				<Snackbar
					open={openError}
					autoHideDuration={6000}
					onClose={handleClose}
				>
					<Alert severity="error">{loginErrorMessage}</Alert>
				</Snackbar>
			</Grid>
		</div>
	);
}

export default AdminLogin;
