// import React from "react";
// import PropTypes from "prop-types";

// // import ProductBuoy from "../assets/images/productBuoy.svg";
// import { makeStyles } from "@mui/styles";
// import { Button, Container, Grid, Typography } from "@mui/material";
// import ProductBuoy from "../Assets/images/productBuoy.svg";
// import { useTranslation } from "react-i18next";
// import { useState } from "react";

// const useStyles = makeStyles((theme) => ({
// 	footerContainer: {
// 		marginTop: theme.spacing(9),
// 	},
// 	root: {
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 		marginTop: theme.spacing(9),
// 		marginBottom: theme.spacing(9),
// 	},
// 	button: {
// 		border: "4px solid currentColor",
// 		borderRadius: 0,
// 		marginTop: theme.spacing(4),
// 		height: "auto",
// 		padding: theme.spacing(2, 5),
// 	},
// 	link: {
// 		marginTop: theme.spacing(3),
// 		marginBottom: theme.spacing(3),
// 	},
// 	buoy: {
// 		width: 60,
// 		margin: "auto",
// 	},
// }));

// function Footer() {
// 	const classes = useStyles();
// 	const [language, setLanguage] = useState("hu");
// 	const { i18n } = useTranslation();

// 	const toggleLang = ({ target: { value } }) => {
// 		// overly simplistic way of toggling between languages
// 		console.log("aaa");
// 		if (Object.values(SupportedLngs).includes(value)) {
// 			i18n.changeLanguage(value);
// 			console.log(value);
// 		}
// 	};
// 	const SupportedLngs = {
// 		hu: "hu",
// 		ro: "ro",
// 	};
// 	return (
// 		<Grid
// 			className={classes.footerContainer}
// 			container
// 			direction="column"
// 			justifyContent="center"
// 			alignItems="center"
// 		>
// 			<Grid item xs={12}>
// 				<Typography variant="subtitle1" className={classes.link}>
// 					csalogany.gyerekfoglalkoztato@gmail.com
// 				</Typography>
// 			</Grid>
// 			{Object.values(SupportedLngs)?.map((lng) => (
// 				<button
// 					key={lng}
// 					style={{ position: "relative", zIndex: 99999 }}
// 					type="button"
// 					value={lng}
// 					onClick={toggleLang}
// 				>
// 					{lng}
// 				</button>
// 			))}
// 			<Grid item xs={12}>
// 				<img src={ProductBuoy} className={classes.buoy} alt="buoy" />
// 			</Grid>
// 		</Grid>
// 	);
// }

// Footer.propTypes = {
// 	classes: PropTypes.object.isRequired,
// };

// export default Footer;
import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";
import { HU } from "country-flag-icons/react/3x2";
export default function Footer() {
	const { t } = useTranslation();
	const toggleLang = (e) => {
		i18n.changeLanguage(e.target.value);
	};

	return (
		<Box
			component="footer"
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === "light"
						? theme.palette.grey[200]
						: theme.palette.grey[800],
				p: 6,
			}}
		>
			<Container maxWidth="lg">
				<Grid container spacing={5}>
					<Grid item xs={12} sm={3}>
						<Typography variant="h6" color="text.primary" gutterBottom>
							{t("changeLanguage")}
						</Typography>
						<Select
							defaultValue="hu"
							style={{ minWidth: 250 }}
							onChange={(e) => toggleLang(e)}
						>
							<MenuItem value={"none"} disabled>
								{t("changeLanguage")}
							</MenuItem>
							<MenuItem value={"ro"}>{t("romanian")}</MenuItem>
							<MenuItem value={"hu"}>{t("hungarian")} </MenuItem>
						</Select>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Typography variant="h6" color="text.primary" gutterBottom>
							{t("contactUs")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{t("addressTitle")}
							{t("address")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							csalogany.gyerekfoglalkoztato@gmail.com
						</Typography>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Typography variant="h6" color="text.primary" gutterBottom>
							{t("followUs")}
						</Typography>
						<Link
							href="https://www.facebook.com/people/Csalog%C3%A1ny-gyerekfoglalkoztat%C3%B3/100093399744491/"
							color="inherit"
						>
							<Facebook />
						</Link>
						{/* <Link
							disabled
							href="https://www.instagram.com/"
							color="inherit"
							sx={{ pl: 1, pr: 1 }}
						>
							<Instagram />
						</Link> */}
					</Grid>
					<Grid item xs={12} sm={3} alignSelf={"center"}>
						<Typography variant="body2">
							Start-Up cofinanțat din Fondul Social European, prin Programul
							Operațional 2014 - 2020, Apel VIITOR PENTRU TINERII NEETs.
						</Typography>
						<Link color="inherit" href="https://www.fonduri-ue.ro/">
							<Typography variant="p8">
								Pentru informații detaliate despre celelate programe cofinanțate
								de Uniunea Europeană, vă invităm să vizitați www.fonduri-ue.ro.
							</Typography>
						</Link>
					</Grid>
				</Grid>
				<Box mt={5}>
					<Typography variant="body2" color="text.secondary" align="center">
						{"Copyright © "}
						<Link color="inherit" href="https://www.csalogany.com/">
							Csalogány
						</Link>{" "}
						{new Date().getFullYear()}
						{"."}
					</Typography>
				</Box>
			</Container>
		</Box>
	);
}
