import "./App.css";
import { ThemeProvider } from "@mui/styles";
import Theme from "./UI/Theme";
import { Routes, Route } from "react-router-dom";
import MainPage from "./Pages/MainPage";
import Apointment from "./Pages/Apointment";

import AdminLogin from "./Pages/AdminLogin";
import Admin from "./Pages/Admin";
import { Helmet } from "react-helmet";
import headerPicture from "./Assets/images/headerPicture.jpg";

function App() {
	return (
		<ThemeProvider theme={Theme}>
			<Helmet>
				<title>Csalogány</title>‍
				<meta
					name="Csalogány gyerekfoglalkoztató"
					content="Lépj be velünk a játék és tanulás varázslatos világába! Fedezd fel velünk a lehetőségeket, és merülj el az új élményekben a játék és tanulás világában!"
				/>
				<meta property="og:title" content="Csalogány" />{" "}
				<meta
					property="og:description"
					content="Csalogány gyerekfoglalkoztató"
				/>
				<meta property="og:image" content={headerPicture} />
				<meta property="og:url" content="https://www.csalogany.com" />
				<meta property="og:site_name" content="Csalogány" />
				<meta property="og:locale" content="ro_RO" />
				<meta property="og:type" content="article" />
			</Helmet>
			<Routes>
				<Route exact path="/" element={<MainPage />} />
				<Route exact path="/apointment" element={<Apointment />} />
				<Route exact path="/admin" element={<Admin />} />
				<Route exact path="/adminlogin" element={<AdminLogin />} />
			</Routes>
		</ThemeProvider>
	);
}

export default App;
