// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, onChildAdded, ref } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAjXHDxI-ZOPgpLaohZ1XCpRamfJv9bmC4",
	authDomain: "csalogany-bbf11.firebaseapp.com",
	databaseURL:
		"https://csalogany-bbf11-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "csalogany-bbf11",
	storageBucket: "csalogany-bbf11.appspot.com",
	messagingSenderId: "517256733514",
	appId: "1:517256733514:web:a37455765d8378dae817b3",
	measurementId: "G-BG3XE0K0GW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
