import { ThemeProvider } from "@mui/styles";
import theme from "../UI/Theme";
import Footer from "./Footer";
import HomePage from "./HomePage";
import Newsletter from "./Newsletter";
import ProductCategories from "./ProductCategories";
import ProductHowItWorks from "./ProductHowItWorks";
import ProductValues from "./ProductValues";
import MainProductCategories from "./MainProductCategories";
import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";
import one from "../Assets/images/1.png";
import two from "../Assets/images/2.png";
import three from "../Assets/images/3.png";

import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

function MainPage() {
	const { t } = useTranslation();
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar color="inherit" position="fixed">
					<Toolbar>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item textAlign={"center"} xs={4}>
								<img border="0" src={one} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={4}>
								<img src={two} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={4}>
								<img src={three} alt="wonder" width="20%" />
							</Grid>
							<Grid item textAlign={"center"} xs={12}>
								<Typography sx={{ fontStyle: "italic" }}>
									Conținutul acestui material nu reprezintă în mod obligatoriu
									poziția oficială a Uniunii Europene sau a Guvernului României
								</Typography>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<Toolbar />
			</Box>
			<HomePage />
			<MainProductCategories />
			<ProductValues />
			<ProductCategories />
			<ProductHowItWorks />
			<Newsletter />
			<Footer />
			<CookieConsent
				location="bottom"
				buttonText={t("cookieAccept")}
				cookieName="myAwesomeCookieName2"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
			>
				{t("cookieText")}
			</CookieConsent>
		</ThemeProvider>
	);
}

export default MainPage;
