import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.common.white,
		position: "relative",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.up("sm")]: {
			height: "87vh",
			minHeight: 500,
			maxHeight: 1300,
		},
	},
	container: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(14),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	backdrop: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: "#000000",
		opacity: 0.5,
		zIndex: -1,
	},
	background: {
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		zIndex: -2,
	},
	arrowDown: {
		position: "absolute",
		bottom: theme.spacing(4),
	},
}));

function Header(props) {
	const { backgroundClassName, children } = props;
	const classes = useStyles();
	return (
		<section className={classes.root}>
			<Container className={classes.container}>
				{children}
				<div className={classes.backdrop} />
				<div className={clsx(classes.background, backgroundClassName)}></div>
			</Container>
		</section>
	);
}

Header.propTypes = {
	backgroundClassName: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	classes: PropTypes.object.isRequired,
};

export default Header;
